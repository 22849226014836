<template>
  <div class="Cooperation">
    <div class="name">
        合作单位
    </div>
    <div class="collapse">
      <div class="about-li">
          <h3><a href="https://www.whu.edu.cn/" target="_blank">1、武汉大学</a> </h3>
          <div class="li-left">
              <img class="img" src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/Cooperation01.png">
              
          </div>
          <div class="li-right">
              <div class="intro">
                  <p>
                      武汉大学（Wuhan University）简称“武大”，是中华人民共和国教育部直属的综合性全国重点大学；位列“世界一流大学和一流学科”、“985工程”、“211工程”；入选学位授权自主审核单位、“珠峰计划”、“强基计划”、“2011计划”、“111计划”、卓越工程师教育培养计划、卓越法律人才教育培养计划、卓越医生教育培养计划、国家建设高水平大学公派研究生项目、国家级新工科研究与实践项目、一流网络安全学院建设示范项目高校、中国政府奖学金来华留学生接收院校、全国深化创新创业教育改革示范高校、国家大学生文化素质教育基地、大众创业万众创新示范基地、基础学科拔尖学生培养计划2.0基地，为欧亚-太平洋大学联盟、大学通识教育联盟、中国高校行星科学联盟、法学教育创新联盟、医学“双一流”建设联盟成员。
                  </p>
              </div>
              <div class="line-bx">
                  <div class="line-h"></div>
                  <h4>联系方式：</h4>
              </div>
              <div class="p-box">

                  <p>邮编：430072</p>
                  <p>地址：湖北省武汉市武昌区八一路299号</p>
                  <p>电子邮箱：wlxxs@whu.edu.cn</p>
                  <p>官网：<a href="https://www.whu.edu.cn/" target="_blank">https://www.whu.edu.cn/</a></p>
              </div>
              <div class="line-x"></div>
          </div>
      </div>
      <div class="about-li">
          <h3><a href="https://www.wanfangdata.com.cn/" target="_blank">2、万方数据知识服务平台</a> </h3>
          <div class="li-left">
              <img class="img" src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/Cooperation02.png">
              
          </div>
          <div class="li-right">
              <div class="intro">
                  <p>
                      万方数据知识服务平台（Wanfang Data Knowledge Service Platform）是在原万方数据资源系统的基础上，经过不断改进、创新而成，集高品质信息资源、先进检索算法技术、多元化增值服务、人性化设计等特色于一身，是国内一流的品质信息资源出版、增值服务平台。
                  </p>
                  <p>
                      万方数据知识服务平台整合数亿条全球优质知识资源，集成期刊、学位、会议、科技报告、专利、标准、科技成果、法规、地方志、视频等十余种知识资源类型，覆盖自然科学、工程技术、医药卫生、农业科学、哲学政法、社会科学、科教文艺等全学科领域，实现海量学术文献统一发现及分析，支持多维度组合检索，适合不同用户群研究。万方智搜致力于“感知用户学术背景，智慧你的搜索”，帮助用户精准发现、获取与沉淀知识精华。万方数据愿与合作伙伴共同打造知识服务的基石、共建学术生态。
                  </p>

              </div>
              <div class="line-bx">
                  <div class="line-h"></div>
                  <h4>联系方式：</h4>
              </div>
              <div class="p-box">

                  <p>服务热线：4000115888</p>
                  <p>电子邮箱：service@wanfangdata.com.cn</p>
                  <p>官   网：<a href="https://www.wanfangdata.com.cn/" target="_blank">https://www.wanfangdata.com.cn/</a></p>
              </div>
              <div class="line-x"></div>
          </div>
      </div>
      <div class="clear"></div>
    </div>
      
  </div>
</template>

<script>
export default {
  name: 'Cooperation',
 components:{},
  data () {
    return {
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
.collapse{
  padding: 20px 0;
  p{
  color: rgba(0, 18, 22, 0.65);
  line-height:1.769230769230769;
  }
  .about-li{
    width: 100%;
    float: left;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 20px;
    h3{
      font-size: 16px;
      padding-bottom: 10px;
      a{
        text-decoration: none;
      }
    }
    .li-left {
        float: left;
        width: 160px;
        img {
            width: 100%;
            height: 100%;
            margin-bottom: 20px;
        }
    }
    .li-right {
        float: left;
        width: 720px;
        padding: 0 20px;
        .line-bx {
            margin-top: 24px;
            .line-h {
                width: 32px;
                height: 6px;
                background: #007DFF;
            }
            h4 {
                margin-top: 8px;
            }
        }
        .p-box {
            margin: 8px auto 32px;
            p {
                line-height: 24px;
                color: rgba(0, 0, 0, 0.65);
            }
        }
        .line-x {
            width: 100%;
            height: 1px;
            background: #ddd;
            display: block;
        }
    }
    ::after {
        clear: both;
        content: " ";
        display: block;
    }
  }
  .clear{
    clear: both;
  }
}
.Cooperation {
    padding: 0 32px 0;
}
.name {
    font-size: 24px;
    font-weight: 600;
    color: rgba(0,18,22,.85);
    line-height: 36px;
    padding: 32px 0 32px 0;
    border-bottom: 1px solid #ebeef5;
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .collapse{
    .about-li{
        .li-right {
            width: 100%;
        }
    }
  }
}

@media screen and (min-width: 1px) and (max-width: 767px)  {
  .collapse{
    .about-li{
        .li-right {
            width: 100%;
        }
    }
  }
}
</style>
